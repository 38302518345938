<template>
  <div>
    <b-row class="m-4">
      <b-col cols="6" class="pb-4">
        <b-form-group :valid-feedback="parseSchedule.message" :invalid-feedback="parseSchedule.message" :state="parseSchedule.state">
          <div v-if="parseSchedule.state">
              <schedule-player
                :width="'100%'"
                :height="'20em'"
                :uri="uri"
                :params="e2v"
              ></schedule-player>
          </div>
          <div v-else>
            <b-skeleton-img width="100%" height="25vw" />
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col v-if="!statusLoaded" cols="1" class="p-2">
            <b-spinner variant="danger" />
          </b-col>
          <b-col class="mx-auto">
            <b-form-group class="p-2" :valid-feedback="parseStatus.message" :invalid-feedback="parseStatus.message" :state="parseStatus.state">
              <b-form-input id="uri" v-model="uri" :state="parseStatus.state" placeholder="e.g. https://api.example.com/e2v"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-overlay :show="!parseStatus.state">
          <b-row>
            <b-col cols="12">
              <b-col>
                <hr class="border" />
                <b-form-group :valid-feedback="parseParameters.message" :invalid-feedback="parseParameters.message" :state="parseParameters.state">
                  <h4>E2V Stream Options
                    <b-button v-if="!parametersLoaded" variant="primary" disabled>
                      <b-spinner small type="grow"></b-spinner>
                      {{ 'Retrieving parameter list' }}
                    </b-button>
                  </h4>
                  <div v-if="lastParameters.data && lastParameters.data.length == 0">
                    No parameters required.
                  </div>
                  <div :key="parameter.slug" v-for="parameter in lastParameters.data">
                    <div v-if="parameter.opts">
                      <multiselect
                        placeholder="Select"
                        :options="parameter.opts"
                        track-by="id"
                        label="name"
                        :preselect-first="true"
                        v-model="selector[parameter.slug]"
                        @select="(opt, id) => { e2v[parameter.slug] = opt.id }"
                      />
                      <b-form-text> {{parameter.desc}} </b-form-text>
                    </div>
                    <div v-else>
                    <b-form-input
                      :id="'e2v_'+parameter.slug"
                      v-model="e2v[parameter.slug]"
                      :state="validSchedule"
                      :placeholder="parameter.name"
                      aria-describedby="input-live-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-live-feedback">
                        {{errors[parameter.slug]}}
                    </b-form-invalid-feedback>
                    <b-form-text :id="'e2v_'+parameter.slug">{{ parameter.desc }}</b-form-text>
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-col>
            <b-col cols="12">
              <b-col>
                <hr class="border" />
                <h4>Campaign Stats</h4>
                <b-row>
                  <b-col>Matched Campaigns</b-col>
                  <b-col><label>{{ totalCampaigns }}</label></b-col>
                </b-row>
                <b-row>
                  <b-col>Shortest Campaign</b-col>
                  <b-col><label>{{ minimumDuration }} seconds</label></b-col>
                </b-row>
                <b-row>
                  <b-col>Longest Campaign</b-col>
                  <b-col><label>{{ maximumDuration }} seconds</label></b-col>
                </b-row>
              </b-col>
              <b-col>
                <hr class="border" />
                <!-- DEFAULT SLOT IS HERE -->
                <slot>
                </slot>
              </b-col>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>

      <!-- :params='{"api_key": "e02189628c1b384fd99c3c8b4624dc1b", "duration": "10"}' -->

  </div>
</template>

<script>
// /* eslint-disable no-unused-vars */
// /* eslint-disable vue/no-unused-components */
// /* eslint-disable no-mixed-spaces-and-tabs */

import axios from 'axios'
import { debounce } from 'lodash'
import SchedulePlayer from './SchedulePlayer.vue'
import Multiselect from 'vue-multiselect'

// emit on change parameters? how do we get the settings up higher?

export default {
    components: {
      SchedulePlayer,
      Multiselect,
    },
    props: {
    },
    data() {
      return {
        timeout: 1000,
        e2v: {},
        parameters: [],
        uri: 'https://api.carswap.me/e2v/sched.php',
        selector: {},
        errors: {},
        validSchedule: false,
        totalCampaigns: 0,
        minimumDuration: 0,
        maximumDuration: 0,
        statusLoaded: false,
        parametersLoaded: false,
        scheduleLoaded: false,
        lastStatus: {},
        lastSchedule: {},
        lastParameters: {},
      }
    },
    computed: {
      // each of these functions acts like a filter that operates on the axios object
      parseStatus() {
        // console.log(this.lastStatus)
        // check if URI empty
        if (this.uri === '') {
          return { state: false, message: 'empty URI' }
        }
        else if (this.lastStatus.code && this.lastStatus.code === 'ECONNABORTED') {
          return { state: false, message: `/status request exceeded timeout of ${this.timeout}ms!` }
        }
        // check if browser error
        else if (this.lastStatus.message) {
          return { state: false, message: 'browser error, check console' }
        }
        else {
          // check if 200
          if (this.lastStatus.status === 200) {
            if (typeof this.lastStatus.data === 'object') {
              if (Object.keys(this.lastStatus.data).length > 0) {
                // check for all status fields
                const fields = ['author', 'company', 'email', 'phone', 'version', 'campaigns']
                for (const field of fields) {
                  if(typeof this.lastStatus.data[field] === 'undefined') {
                    return { state: false, message: `field '${field}' is missing from /status` }
                  }
                }
                return { state: true, message: '/status response valid' }
              }
              else {
                return { state: false, message: '/status response is an empty array' }
              }
            }
            else {
              return { state: false, message: '/status did not return an object' }
            }
          }
          else {
            return { state: false, message: 'endpoint did not return HTTP 200' }
          }
        }
      },
      parseParameters() {
        // check for duplicate name/slug
        return { state: true, message: '/parameters response valid' }
      },
      parseSchedule() {
        if (this.lastSchedule.data?.errors) {
          return { state: false, message: 'parameter validation returned errors' }
        }
        else if (this.lastSchedule.data == '0' || this.lastSchedule.data == '[]') {
          return { state: false, message: 'empty schedule response' }
        }
        else {
          if (this.lastSchedule.data?.DisplayGroup?.Sequence) {
            return { state: true, message: '/getSchedule looks good' }
          }
          else {
            return { state: false, message: 'DisplayGroup.Sequence is not defined' }
          }
        }
      },
      parseStatusError() {
        return 'WHOOPS'
      },
    },
    mounted() {
      this.fetchStatus()
    },
    methods: {
      fetchStatus() {
        this.statusLoaded = false
        axios.get(this.uri + '?_path=/status', { timeout: this.timeout }).then(
          (r) => {
            this.lastStatus = r
            this.statusLoaded = true
            this.fetchParams()
          },
        ).catch(
          (r) => {
            this.lastStatus = r
            this.statusLoaded = true
          }
        )
      },
      fetchParams() {
        this.parametersLoaded = false
        axios.get(this.uri, {
          params: {
            '_path': '/parameters'
          }
        })
        .then((response) => {
          if (typeof(response.data) === 'object') {
            this.lastParameters = response
            this.parametersLoaded = true
            console.log(this.lastParameters.data)
            for (let param of this.lastParameters.data) {
              this.$set(this.e2v, param.slug, '')
            }
            this.parametersLoaded = true
            if (this.parseParameters.state)
              this.fetchSchedule()
          }
          else {
            this.parametersLoaded = true
          }
        })
        .catch((response) => {
          this.lastParameters = response
        })
      },
      fetchSchedule() {
        axios.post(this.uri+'?_path=/getSchedule', this.e2v).then(
          (response) => {
            this.lastSchedule = response
            if (response.data != '0') {
              if (response.data.errors) {
                this.validSchedule = false
                this.errors = response.data.errors
              }
              else if (response.data?.DisplayGroup?.Sequence) {
                this.validSchedule = true
                let campaigns = response.data.DisplayGroup.Sequence[0].Campaign
                this.totalCampaigns = campaigns.length
                this.minimumDuration = campaigns.reduce((a,c) => Math.min(a, c.duration), Number.MAX_SAFE_INTEGER)
                this.maximumDuration = campaigns.reduce((a,c) => Math.max(a, c.duration), 0)
              }
            }
          },
          () => {
            console.log('retrieving failed')
          }
        )
      },
    },
    beforeDestroy() {
    },
    watch: {
      uri() {
        this.fetchStatus()
      },
      e2v: {
        deep: true,
        handler: debounce(function() {
          this.fetchSchedule()
          this.$emit('changed', this.e2v)
        }, 500)
      },
    },
}

</script>

<style scoped>
.player-wrapper {
  resize: both;
}
</style>
